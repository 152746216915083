import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledFormCouponCreate= styled.div`
  padding-top: ${Rem(40)};
  @media (${BP.ipad}) {
    padding-top: ${Rem(50)};
  }
`;

export const StyledFormCouponCreateSubmit = styled.div`
  position: relative;
  padding-top: ${Rem(30)};
  padding-bottom: ${Rem(30)};
  display: flex;
  justify-content: center;

  @media (${BP.ipad}) {
    padding-top: ${Rem(40)};
  }
`;

export const StyledLogoBrand = styled.img`
  width:50%;
  position: absolute;
  top: ${Rem(100)};
  left:25%;
  z.index:10;
  @media (${BP.ipad}) {
    width: ${Rem(200)};
    left:46%;
  }
`;
