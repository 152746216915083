import React from "react";
import {
  StyledInfoModal,
  StyledInfoModalTitleBlock,
  StyledInfoModalBigText,
  StyledInfoModalFormBlock,
} from "./style";

export default ({ message }) => {
  return (
    <StyledInfoModal>
      <StyledInfoModalTitleBlock>
        <StyledInfoModalBigText>
          Bzzz...
        </StyledInfoModalBigText>
      </StyledInfoModalTitleBlock>

      <StyledInfoModalFormBlock>
        {message}
      </StyledInfoModalFormBlock>
    </StyledInfoModal>
  );
};
