import React, { useEffect, useState, useContext } from "react";
import CreateAdoption from "../components/FormCouponCreate/FormCouponCreate";
import { DataContext } from "../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Meta from "../components/Meta/Meta";

const RedeemCoupon = (props) => {
  const { data } = props.pageContext;
  const { labels } = data;
  const { 
    pageImages,
  } = useContext(DataContext);
  //const id = beekeeper.id
  const [coupon, setCoupon] = useState("");
  const [honey_id, setHoney] = useState("");
  const [beekeeper_id, setBeekeeper] = useState("");
  const [hive_id, setHive] = useState("");
  const imageData = pageImages.find((e) => e.path === "/2020/images/sorgenia.png");

  // Get parameter from urls.
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _coupon = urlParams.get("coupon");
    const _honey_id = urlParams.get("honey_id") ?? 5; // Millefiori
    const _beekeeper_id = urlParams.get("beekeeper_id") ?? 2; // Riccardo
    const _hive_id = urlParams.get("hive_id") ?? 59; // Mughetto

    if(_coupon){
      setCoupon(_coupon);
    }
    if(_honey_id ){
      setHoney(parseInt(_honey_id));
    }
    if(_beekeeper_id){
      setBeekeeper(parseInt(_beekeeper_id));
    }
    if( _hive_id){
      setHive(parseInt(_hive_id));
    }
  }, []);

  return (
    <>  
      <Meta />
      <div style={{ height: 150 }}></div>
      <div style="width:180px; position: absolute; top: 50px; right: 40px">
        <GatsbyImage
          image={getImage(imageData)}
          alt={imageData?.alt}
        />
      </div>
      <div style ="display: flex; justify-content: center;">
        <CreateAdoption labels={labels} coupon={coupon} hive_id={hive_id} honey_id={honey_id} beekeeper_id={beekeeper_id}/>
      </div>
      <div style={{ height: 50 }}></div>
    </>
  );
};

export default RedeemCoupon;
