import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledInfoModal = styled.div`
  display: flex;
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding-top: ${Rem(40)};
  padding-bottom: ${Rem(50)};
  flex-direction: column;

  @media (${BP.tablet}) {
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(100)};
  }
`;

export const StyledInfoModalTitleBlock = styled.div`
  flex-basis: 100%;
  padding-left: ${Rem(10)};
  padding-top: ${Rem(10)};

  @media (${BP.tablet}) {
    padding-top: ${Rem(0)};
  }
`;

export const StyledInfoModalBigText = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(24)};
  color: ${Colors.yellow};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`;

export const StyledInfoModalFormBlock = styled.div`
  padding: ${Rem(15)};
  font-size: ${Rem(20)};
`;
