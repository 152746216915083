import React, { useState, useEffect, useContext } from "react";
import { StyledFormCouponCreate, StyledFormCouponCreateSubmit, StyledLogoBrand } from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import { LayoutContext } from "../../contexts/LayoutContext";
import Loader from "../Loader/Loader";
import useApi from "../../hooks/useApi";
import { DataContext } from "../../contexts/DataContext";

import {
  StyledForm,
  StyledFormFieldset,
  StyledFormBlock,
  StyledFormInput,
  StyledFormSubmit,
  StyledFormCouponDoneText,
  StyledTitleFormCreate,
} from "../../commons/Form";
import { StyledTitle } from "../../commons/Typography";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import InfoModal from "../InfoModal/InfoModal.jsx";

export default ({
  positiveMessages, negativeMessages, couponSet, standAloneForm = true, title = "Riscatta il tuo codice", honey_id, hive_id, beekeeper_id, visibility = "always",
}) => {
  const [formData, setFormData] = useState({
    lang: "it",
    grecaptcharesponse: "token",
  });
  const [adoptionCreate, setAdoptionCreate] = useState(false);
  const [lang, setLang] = useState("it");
  const { setModalContent } = useContext(LayoutContext);
  const { currentLanguageSuffix } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const { postAdoption } = useApi();
  const { labels, languages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const [coupon, setCoupon] = useState(couponSet);
  const visible = visibility === "always" ? true : coupon !== null;

  useEffect(() => {
    const _queryString = window.location.search;
    const _urlParams = new URLSearchParams(_queryString);
    const _coupon = couponSet ? couponSet : _urlParams.get("coupon");

    setCoupon(_coupon);

    if (currentLanguageSuffix)
      setLang(currentLanguageSuffix.replace("/", ""));
    setFormData({
      ...formData,
      coupon: coupon,
    });
  }, []);

  // Send Error when post not Work
  return (
    <>
      {visible && (
        <StyledTitleFormCreate standAlone={standAloneForm}>
          <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
          {!adoptionCreate && (
            <StyledFormCouponCreate id="FormCouponCreate">
              <Loader isVisible={isLoading} />
              <StyledGridRow>
                <StyledForm
                  Validate
                  onSubmit={(event) => {
                    event && event.preventDefault();
                    if (formData.name.split(" ").length < 2) {
                      setModalContent(<InfoModal message={labels.NAME_SURNAME_ERROR} />);
                      return;
                    }
                    setIsLoading(true);
                    if (formData.coupon)
                      var _coupon = formData.coupon;
                    else
                      var _coupon = coupon;
                    postAdoption(formData.name, formData.email, hive_id, honey_id, beekeeper_id, _coupon, lang).then((res) => {
                      if (res.error) {
                        setModalContent(<InfoModal message="Errore Coupon" />);
                      } else {
                        setAdoptionCreate(res.data);
                      }
                      setIsLoading(false);
                    });
                    event.target.reset();
                    return false;
                  }}
                >
                  <StyledFormFieldset>
                    <StyledFormBlock>
                      <StyledFormInput
                        id="submit-adoption-coupon-user"
                        type="text"
                        placeholder={labels.NAME_SURNAME}
                        required
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            name: e.currentTarget.value,
                          });
                        }}
                      />

                    </StyledFormBlock>
                    <StyledFormBlock>
                      <StyledFormInput
                        id="submit-adoption-coupon-email"
                        type="email"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$"
                        placeholder={labels.MAIL_ADDRESS}
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            email: e.currentTarget.value,
                          });
                        }}
                      />
                    </StyledFormBlock>
                    <StyledFormBlock>
                      <StyledFormInput
                        id="submit-adoption-coupon-coupon"
                        type="coupon"
                        placeholder={coupon ? coupon : labels.COUPON_PLACEHOLDER}
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            coupon: e.currentTarget.value,
                          });
                        }}
                      />
                    </StyledFormBlock>
                  </StyledFormFieldset>
                  <StyledFormCouponCreateSubmit>
                    <StyledFormSubmit type="submit">
                      {labels.SUBMIT_CHOOSES}
                      <Icon icon={icons.arrowRight} color={Colors.white} />
                    </StyledFormSubmit>
                  </StyledFormCouponCreateSubmit>
                </StyledForm>
                <div style="padding: 10px 0px; text-align: center;">
                  <a style="font-size: 12px; font-weight: 100; text-decoration: underline;" href={`https://3bee-assets.s3.eu-south-1.amazonaws.com/documents/${currentLanguageCode}/termini_condizioni_voucher.pdf`}>{labels.COUPONRULES}</a>
                </div>
              </StyledGridRow>
            </StyledFormCouponCreate>
          )}
          {adoptionCreate && (
            <StyledFormCouponDoneText
              dangerouslySetInnerHTML={{ __html: labels.ADOPTION_DONE }}
            >
            </StyledFormCouponDoneText>
          )}
        </StyledTitleFormCreate>)}
    </>
  );
};
